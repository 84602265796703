<template lang="pug">
.credentials
    v-row.mb-2
        v-card.mb-5(width="99%")
            v-card-title(left).mb-3
                .text-h6 Credentials
                v-btn.white--text(
                    :title='showPasswords ? "Hide sensitive information" : "Show sensitive information"', color='brand'
                    icon,
                    @click='showPasswords = !showPasswords',
                    )
                        v-icon {{ showPasswords ? 'visibility_off' : 'visibility' }}
            v-card-text
                v-form(v-model="isValid" lazy-validation ref="form")
                    v-container(v-for='credential in localCredentials' :key="credential.id" style="border-bottom: 1px solid #ccc;")
                        v-row(v-if="deviceType === 'machine'")
                            v-col(cols='4' )
                                v-select(
                                    v-if="Boolean(credential.accountType)"
                                    label='Account Type'
                                    v-model='credential.accountType'
                                    readonly
                                    :items='accountTypes'
                                    :rules="[rules.required]"
                                    item-text='name'
                                    item-value='value'
                                    dense, color='brand', outlined
                                )
                                v-select(
                                    v-else
                                    label='Account Type'
                                    v-model='credential.accountType'
                                    :items='accountTypes'
                                    :rules="[rules.required]"
                                    @change="onChangeAccountType(credential)"
                                    item-text='name'
                                    item-value='value'
                                    dense, color='brand', outlined
                                )
                            v-col(cols='7' )
                                v-text-field(
                                    label="Domain Name"
                                    v-model.trim='credential.domain'
                                    dense, color='brand', outlined
                                )
                        v-row.mt-0
                            v-col(cols='5')
                                v-text-field.pr-2(
                                    label='Username'
                                    v-model.trim='credential.username'
                                    append-icon='content_copy'
                                    :rules="[rules.required, rules.uniqueName, rules.noInternalSpaces]"
                                    @click:append='copyToClipboard(credential.username)'
                                    @input="onChange(credential)"
                                    dense, color='brand', outlined
                                )
                            v-col.pr-2(cols='5')
                                v-text-field(
                                    label='Password'
                                    v-model.trim='credential.password'
                                    :type='showPasswords ? "text" : "password"'
                                    append-icon='content_copy'
                                    :rules="[rules.required]"
                                    @click:append='copyToClipboard(credential.password)'
                                    @input="onChange(credential)"
                                    dense, color='brand', outlined
                                )
                            v-col()
                                v-btn.white--text(
                                    color="red"
                                    @click="deleteCredential(credential)"
                                ) delete


            v-card-actions
                v-btn.brand.white--text(
                    :disabled="!isValid"
                    @click='addCredential'
                ) Add Credential

</template>

<script lang="js">
import dti from 'dti';
export default {
    name:'credentials',
    emits: ['update', 'delete', 'formValid'],
    props: {
        customersCredentials: {
            type: Array,
            required: true,
        },
        deviceId: {
            required: true,
        },
        deviceType: {
            type: String,
            required: true,
        },
        customerId: {
            type: Number,
            required: true,
        },
        jiggle: {
            type: Boolean
        },
    },
    data () {
        return {
            showPasswords: false,
            editedItem: {},
            isValid: false,
            localCredentials: [],
            password: '',
            username:'',
            defaultItem: {
                type: '',
                accountType: null,
                username: '',
                password: '',
                domain: '',
                notes: '',
                id: null,
                customersCommId: null,
                customersMachineId: null
            },
            accountTypes: [
                {
                    name: 'Local Account',
                    value: 'local'
                },
                {
                    name: 'Domain Account',
                    value: 'domain'
                },
            ],
            rules: {
                required: value => !!value || 'Required.',
                uniqueName: value => {
                    let duplicate = null;
                    let result = true;
                    const v = `${value}`.trim().toLowerCase();
                    if(this.editedIndex === -1){
                        // new item
                        this.localCredentials.forEach(cred => {
                            if(cred.username.trim().toLowerCase() === v){
                                duplicate = true;
                            }
                        });
                    } else {
                        let counter = 0;
                        this.localCredentials.forEach(cred => {
                            if(cred.username.trim().toLowerCase() === v){
                                counter += 1;
                            }
                        });
                        duplicate = counter > 1;
                    }
                    if(duplicate) {
                        result = 'Must be unique.';
                    }
                    return result;
                },
                noInternalSpaces: value => {
                    let result = true;
                    const v = `${value}`.trim();
                    if(v.indexOf(' ') > -1){
                        result = 'No spaces allowed.';
                    }
                    return result;
                }
            }
        };
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Credential' : 'Edit Credential';
        },
    },
    watch: {
        deviceId: {
            handler (val) {
                if(val) this.initialize();
            },
            immediate: true
        },
        isValid (val){
            this.$emit('formValid', val);
        },

    },
    inject: [
        'copyToClipboard'
    ],
    methods: {

        initialize () {
            console.group('credential: init');
            console.log('DeviceID', this.deviceId);
            this.localCredentials = [];
            this.isValid = false;

            this.customersCredentials.forEach(credential => {
                const newCredential = Object.assign({},credential);
                newCredential.customersCommId = this.deviceId;
                this.localCredentials.push(newCredential);
            });
            console.log('localCredentials', this.localCredentials.size);
            this.validateForm();
            console.groupEnd();
        },

        onChangeAccountType (credential) {
            console.log('onChangeAccountType', credential.accountType);
            if(credential.accountType === 'local'){
                credential.domain = '';
            }
            this.onChange(credential);
        },

        validateForm (){
            this.isValid = false;
            this.$nextTick(() => {
                this.isValid = this.$refs.form && this.$refs.form.validate();
                this.$emit('formValid', this.isValid);
            });
        },

        addCredential () {
            const newCredential = Object.assign({}, this.defaultItem);
            newCredential.type = this.deviceType;
            newCredential.customerId = this.customerId;
            newCredential.id = dti.makeuuid();
            switch (this.deviceType) {
            case 'machine':
                newCredential.customersMachineId = this.deviceId;
                break;
            case 'comm':
                newCredential.customersCommId = this.deviceId;
                break;
            }

            this.localCredentials.push(newCredential);
            this.validateForm();
        },

        updateCredential (item){
            const foundIndex = this.localCredentials.findIndex(cred => cred.id === item.id);
            this.$set(this.localCredentials, foundIndex, item);
            this.$emit('update',item);
        },

        deleteCredential (item){
            const deletedIndex = this.localCredentials.findIndex(cred => cred.id === item.id);
            if(deletedIndex > -1 ){
                this.localCredentials.splice(deletedIndex, 1);
                this.validateForm();
                this.$emit('delete', item);
            }
        },

        /**
         * Update the device
         */
        onChange (credential) {
            this.isValid = this.$refs.form.validate();

            if(this.isValid){
                this.updateCredential(credential);
            }
        },
    }
};
</script>
