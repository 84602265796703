<template lang='pug'>
v-text-field.dateInput(:value='formattedDate', :label='label', outlined, dense, color='brand', hide-details, hint='MM/DD/YYYY format', @blur='onBlur', @keydown.enter='onEnter')
    template(v-slot:append-outer)
        v-menu(:close-on-content-click='false', :return-value.sync='date', transition='scale-transition', offset-y, v-model='menu', ref='picker', max-width='290')
            template(v-slot:activator='{ on, attrs }', color='brand')
                v-btn(icon, v-bind='attrs', v-on='on', x-small)
                    v-icon(color='brand') event
            v-date-picker(v-model='date', scrollable, no-title, light)
                v-spacer
                v-btn(text, @click='menu = false', color='brand') Cancel
                v-btn(text, @click='clearDate', color='brand') Clear
                v-btn(text, @click='$refs.picker.save(date)', color='brand') Ok
</template>
<script>
export default {
    props: {
        value: {
            type: String,
            default () {
                return '';
            }
        },
        label: {
            type: String,
            default () {
                return 'Date';
            }
        }
    },
    data () {
        return {
            date: this.value,
            formattedDate: null,
            menu: false
        };
    },
    methods: {
        formatDate (dateString) {
            if (!dateString) {
                return null;
            }
            const [year, month, day] = dateString.split('-');
            return `${month}/${day}/${year.slice(-2)}`;
        },
        onBlur (event) {
            const newDateString = event.target.value;
            this.processDateString(newDateString);
        },
        onEnter (event) {
            const newDateString = event.target.value;
            const isValidDate = this.processDateString(newDateString);
            if (isValidDate) {
                event.target.blur();
            }
        },
        async clearDate () {
            this.menu = false;
            await this.$nextTick();
            this.processDateString('');
        },
        processDateString (newDateString) {
            newDateString = newDateString.trim();
            if (!newDateString) {
                this.date = null;
                return true;
            }
            function isDateValid (dateStr) {
                return !isNaN(new Date(dateStr));
            }
            let [month, day, year] = newDateString.split('/');
            const currentDate = new Date();
            month = month || (currentDate.getMonth() + 1).toString();
            day = day || currentDate.getDate().toString();
            year = year || currentDate.getFullYear().toString();
            if (year.length === 2) {
                year = `20${year}`;
            }
            const parsedDate = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
            if (isDateValid(parsedDate)) {
                this.date = parsedDate;
                return true;
            } else {
                this.date = null;
                return false;
            }
        }
    },
    watch: {
        date: {
            immediate: true,
            handler (newDate) {
                this.formattedDate = this.formatDate(newDate);
            }
        },
        formattedDate: {
            immediate: true,
            handler: function () {
                if (this.$props.value !== this.date) {
                    this.$emit('input', this.date);
                    this.$emit('datechanged');
                }
            }
        },
        value (newValue) {
            this.date = newValue;
        }
    },
    mounted () {
        window.datepickervm = this;
    }
};
</script>
<style lang="scss" scoped>
.dateInput {
    width: 130px !important;
    max-width: 130px !important;
}
</style>
