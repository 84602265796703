<template lang="pug">
.machinesManager
    v-card.ml-8(light)
        v-card-title.brand.white--text.py-2 Machines
        v-card-subtitle
            v-text-field(
                v-model="search"
                append-icon="search"
                label="Search"
                single-line
                hide-details
                color="brand"
                clearable, clear-icon='clear'
            )
        v-card-text
            v-data-table(
                :headers="headers"
                :items="localDevices"
                :search="search"
                item-key="name"
                sort-by="name"
                dense
                @click:row='editItem'
            )
                template(v-slot:item.notes="{ item }")
                    v-icon(v-if='item.notes') note
                template(v-slot:top)
                    v-toolbar(flat)
                        v-spacer
                        v-dialog(
                            v-model='dialog'
                            max-width='900px'
                            light
                        )
                            template(v-slot:activator="{on, attrs}")
                                v-btn.brand.white--text(
                                    class="mb-2"
                                    v-bind="attrs"
                                    v-on="on"
                                ) New Machine

                            v-card
                                v-card-title.brand.white--text {{ formTitle }}
                                v-card-text
                                    v-container
                                        v-form( ref="form" v-model="valid" lazy-validation)
                                            //- ALL
                                            v-row
                                                v-col
                                                    v-text-field(
                                                        label='Name'
                                                        v-model='editedItem.machineName'
                                                        :rules="[rules.required, rules.uniqueName]"
                                                        outlined, dense, color='brand'
                                                    )
                                                v-col
                                                    v-select(
                                                        label='Device Type',
                                                        v-model='editedItem.type'
                                                        :items='machineTypes'
                                                        item-text='name'
                                                        item-value='value'
                                                        :rules="[rules.required]"
                                                        outlined, dense, color='brand'
                                                    )
                                                v-col(v-if='editedItem.type === "server"')
                                                    v-select(
                                                        label='Designation'
                                                        v-model='editedItem.designation'
                                                        :items='serverDesignations'
                                                        item-text='name'
                                                        item-value='value'
                                                        outlined, dense, color='brand')
                                            v-row(v-if='editedItem.type === "server"')
                                                v-col.flex-grow-0.flex-shrink-1
                                                    v-select(
                                                        label='Hosted'
                                                        v-model="editedItem.hosted"
                                                        :items='yesNo'
                                                        item-text='name'
                                                        item-value='value'
                                                        outlined, dense, color="brand"
                                                    )
                                                v-col.flex-grow-1.flex-shrink-0
                                                    v-text-field(
                                                        label='URL'
                                                        v-model='editedItem.url'
                                                        outlined, dense, color="brand"
                                                    )
                                            v-row
                                                v-col
                                                    v-text-field(
                                                        label='Domain Name'
                                                        v-model='editedItem.domainName'
                                                        :rules="[rules.required]"
                                                        outlined, dense, color="brand"
                                                    )
                                            v-row
                                                v-col
                                                    v-text-field(
                                                        label='IP Address'
                                                        v-model='editedItem.ipAddress'
                                                        :rules='rules.ipRules.always'
                                                        outlined, dense, color="brand"
                                                    )
                                                v-col
                                                    v-text-field(
                                                        label='Gateway Address'
                                                        v-model='editedItem.gatewayAddress'
                                                        :rules='rules.ipRules.always'
                                                        outlined, dense, color="brand"
                                                        placeholder="0.0.0.0"
                                                    )
                                                v-col
                                                    v-text-field(
                                                        label='Subnet Mask'
                                                        v-model='editedItem.subnetMask'
                                                        :rules='rules.ipRules.optional'
                                                        outlined, dense, color="brand"
                                                        placeholder="255.255.255.0"
                                                    )
                                            v-row
                                                v-col
                                                    v-text-field(
                                                        label='OS Version'
                                                        v-model='editedItem.osVersion'
                                                        :rules="[rules.required]"
                                                        outlined, dense, color="brand"
                                                    )
                                                v-col
                                                    datepicker(
                                                        label='Install Date'
                                                        model='editedItem.installDate'
                                                        :rules="[rules.required]"
                                                        outlined, dense, color="brand"
                                                    )
                                            v-row()
                                                v-col
                                                    credentials(
                                                        :customersCredentials = 'credentials.filter(c => c.customersMachineId === editedItem.id)',
                                                        :deviceId='editedItem.id',
                                                        :deviceType="localDeviceType",
                                                        :customerId="customerId"
                                                        @delete="onDeleteCredential",
                                                        @update="onUpdateCredential",
                                                        @formValid="onCredValidChange"
                                                        color='brand'
                                                        )
                                            v-row()
                                                v-col
                                                    .text-h6 Notes
                                                    VueTrix.notesEditor(
                                                        v-model='editedItem.notes',
                                                        placeholder='Notes'
                                                    )
                                        //- End Form
                                v-card-actions
                                    v-btn.actions.red.white--text(
                                        text
                                        :disabled="!allValid"
                                        @click="dialogDelete = true"
                                    ) Delete
                                    v-spacer
                                    v-btn.actions.orange.white--text(
                                        text
                                        @click="close"
                                    ) Cancel
                                    v-btn.actions.brand.white--text(
                                        text
                                        :disabled="!allValid"
                                        @click="save"
                                    ) Save
                        v-dialog(
                            v-model='dialogDelete'
                            max-width='500px'
                            light
                        )
                            v-card
                                v-card-title.brand.white--text Confirm Delete
                                v-card-text
                                    span.text-h6 Are you sure you want to delete this device?
                                v-card-actions
                                    v-spacer
                                    v-btn.orange.white--text(
                                        text
                                        @click="closeDelete"
                                    ) Cancel
                                    v-btn.brand.white--text(
                                        text
                                        @click="deleteItemConfirm"
                                    ) OK
                template(v-slot:item.types="{ item }")
                    span {{ item.type }}
                template(v-slot:item.credentials='{item}')
                    span {{ credentialsPerDevice(item.id) }}
                template(v-slot:no-data)
                    v-btn(
                        color="primary"
                        @click="initialize"
                    ) Reset
</template>

<script>

import ipRegex from 'ip-regex';
import VueTrix from 'vue-trix';
import dti from 'dti';
import datepicker from '@/components/common/datepicker';
import credentials from '@/components/common/credentials';

export default {
    name:'machinesManager',
    components: {
        VueTrix,
        datepicker,
        credentials
    },
    props :{
        updateMachines: {
            type: Function,
            required: true
        },
        credentials: {
            type: Array,
            required: true
        },
        devices: {
            type: Array,
            required: true
        },
        customerId: {
            type: Number,
            required: true
        },
        jiggle: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            localDeviceType: 'machine',
            localCredentials: [],
            localDevices: [],
            dialog: false,
            dialogDelete: false,
            editedIndex: -1,
            editedItem: {
            },
            defaultDevice: {
                designation: 'primary',
                url: '',
                hosted: false,
                machineName: '',
                domainName: 'LocalDomain',
                ipAddress: '0.0.0.0',
                gatewayAddress: '0.0.0.0',
                osVersion: '',
                installDate: new Date().toISOString(),
                notes: ''
            },
            defaultCredential: {
                type: '',
                accountType: null,
                username: '',
                password: '',
                domain: '',
                notes: '',
                add: false,
                edit: false
            },
            machineTypes: [
                {
                    name: 'Server',
                    value: 'server'
                },
                {
                    name: 'Workstation',
                    value: 'workstation'
                }
            ],
            dialogTitle: '',

            // this component
            valid: false,
            // credentials component
            credentialsValid: false,

            search: '',
            headers: [
                { text: 'Name', value: 'machineName', filterable: true},
                { text: 'Machine Type', value: 'type', filterable: true},
                { text: 'Designation', value: 'designation', filterable: true},
                { text: 'Credentials', value: 'credentials', filterable: false },
                { text: 'Notes', value: 'notes', filterable: false },
            ],
            rules: {
                ipRules: {
                    optional: [
                        value => !value || ipRegex({exact: true}).test(value) || 'Invalid IP Address'
                    ],
                    always: [
                        value => ipRegex({exact: true}).test(value) || 'Invalid IP Address'
                    ],

                },
                required: value => !!value || 'Required.',
                uniqueName: value => {
                    let duplicate = null;
                    let result = true;
                    const v = value.trim().toLowerCase();
                    if(this.editedIndex === -1){
                        // new item
                        duplicate = this.localDevices.find(ld => ld.machineName.trim().toLowerCase() === v);
                    } else {
                        // edited item
                        duplicate = this.localDevices.find(ld => ld.machineName.trim().toLowerCase() === v && ld.id !== this.editedItem.id);
                    }
                    if(duplicate) {
                        result = 'Must be unique.';
                    }
                    return result;
                }
            },
            serverDesignations: [
                {
                    name: 'Primary',
                    value: 'primary',
                },
                {
                    name: 'Secondary',
                    value: 'secondary',
                },
                {
                    name: 'Tertiary',
                    value: 'tertiary',
                },
                {
                    name: 'Other',
                    value: 'other'
                }
            ],
            yesNo: [
                {name: 'Yes', value: true},
                {name: 'No', value: false}
            ],

        };
    },
    computed: {
        formTitle () {
            return this.editedIndex === -1 ? 'New Machine' : 'Edit Machine';
        },
        allValid () {
            if(this.dialog){
                return this.valid && this.credentialsValid;
            }
            return false;
        }
    },
    watch: {
        dialog (val) {
            if(this.editedIndex < 0 && !this.editedItem.id){
                this.editedItem.id = dti.makeuuid();
                this.editedItem.notes = "";
            }
            if(val){
                this.$nextTick( () => {
                    this.valid = this.$refs.form && this.$refs.form.validate();
                });
                return true;

            }
            this.close();
        },
        dialogDelete (val) {
            val || this.closeDelete();
        },
        customerId: {
            immediate: true,
            handler (val){
                if(val) this.initialize();
            }
        },
        jiggle: {
            immediate: true,
            handler (val, prev) {
                if(val != prev) this.initialize();
            }
        }

    },
    methods: {
        initialize () {
            console.group('machinesManager.initialize');
            // clone devices to prevent mutation of the prop
            this.localDevices = [];
            console.groupCollapsed('devices');
            this.devices
                .forEach((device) => {
                    console.log('device', device.id);
                    const clonedDevice = Object.assign({}, device);
                    this.localDevices.push(clonedDevice);
                });
            console.groupEnd();
            // clone credentials to prevent mutation of the prop
            // will contain credentials for multiple machines/comms
            this.localCredentials = [];
            console.groupCollapsed('credentials');
            this.credentials
                .filter(c => c.type === this.localDeviceType)
                .forEach((credential,i) => {
                    console.log(i, 'credential', credential.id, credential.username, credential.customersCommId);
                    const clonedCredential = Object.assign({}, credential, {edit:false, add:false});
                    this.localCredentials.push(clonedCredential);
                });
            console.groupEnd();
            this.editedItem = Object.assign({}, this.defaultDevice);
            console.groupEnd();
        },

        onCredValidChange (isValid) {
            this.credentialsValid = isValid;
        },

        credentialsPerDevice (deviceId){
            return this.localCredentials.filter(i => i.customersMachineId  === deviceId ).length;
        },

        onAddCredential (credential){
            credential.add = true;
            this.localCredentials.push(credential);
        },

        onDeleteCredential (item) {
            const index = this.localCredentials.findIndex(c => c.id === item.id);
            if(index > -1){
                this.localCredentials.splice(index,1);
            }
        },

        onUpdateCredential (credential) {
            const index = this.localCredentials.findIndex(c => c.id === credential.id);
            if(index > -1){
                credential.edit = true;
                this.localCredentials[index] = credential;
            } else {
                this.onAddCredential(credential);
            }
        },

        editItem (item) {
            this.editedIndex = this.localDevices.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.valid = false;
            this.dialog = true;
            this.$nextTick(() => {
                this.valid = this.$refs.form && this.$refs.form.validate();
            });
        },

        deleteItem (item) {
            console.log('machinesMgr: deleteItem fired', item.id);
            this.editedIndex = this.localDevices.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.dialogDelete = true;
        },

        deleteItemConfirm () {
            console.group('machinesMgr: deleteItemConfirm', this.editedItem.id, this.editedItem.type);
            this.localDevices.splice(this.editedIndex, 1);
            this.localCredentials = this.localCredentials.filter(c => c.customersMachineId !== this.editedItem.id);
            this.updateMachines(
                this.editedItem,
                this.localCredentials,
                'machine'
            );
            this.closeDelete();
            console.groupEnd();
        },

        close () {
            this.dialog = false;
            this.editedItem.customerId = this.customerId;
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultDevice);
                this.editedIndex = -1;
                this.valid = false;
            });
        },

        closeDelete () {
            this.dialogDelete = false;
            this.dialog = false;

            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultDevice);
                this.editedIndex = -1;
            });
        },

        /**
         * Performs sends the devices and credentials back to customers
         */
        save () {
            if (this.editedIndex > -1) {
                this.editedItem.edit = true;
                Object.assign(this.localDevices[this.editedIndex], this.editedItem);
            } else {
                this.editedItem.add = true;
                this.localDevices.push(this.editedItem);
            }

            this.updateMachines(
                this.editedItem,
                this.localCredentials,
                'machine'
            );
            console.log('save done');
            this.close();
        },

        /**
         * Copies the specified text to the clipboard.
         *
         * @param {String} text
         */
        copyToClipboard (text) {
            navigator.clipboard.writeText(text);
        },

    }
};


</script>

<style lang="css">
.actions.v-btn--disabled.v-btn--text {
    background-color: #ddd !important;
}

.actions.v-btn--disabled>span {
    color: #777 !important;
    background-color: #ddd !important;
}
</style>
